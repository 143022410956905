import Title from "components/Title";
import React from "react";
import Form from "./Form";
import FooterFooter from "./FooterFooter";
import SocialIcons from "./SocialIcons";
import Accordion from "components/Accordion";

function Footer({ showQna = false, textBelowForm }) {
  return (
    <>
      <img
        src="images/curved-blue.svg"
        className="-z-50 w-full mb-[-13%]"
        alt=""
      />
      <div className="mt-25px py-30px md:py-60px bg-blue relative ">
        <div className="container ">
          <div className="mb-12 lg:mb-24">
            <div className="mb-50px sm:mb-75px xl:mb-100px">
              <Form />

              {textBelowForm && (
                <p className="text-center text-sm text-white opacity-70 mt-8 max-w-[46rem] mx-auto">
                  {textBelowForm}
                </p>
              )}
            </div>

            <div className="mb-25px xl:mb-50px">
              <Title title="contact us" className="text-white" />
            </div>

            <div>
              <SocialIcons />
            </div>
          </div>
        </div>
        <div className="mt-[100px] sm:mt-0">
          <FooterFooter arrowUpShow={true} />
        </div>
      </div>
    </>
  );
}

export default Footer;
