import Point from "components/Point";
import Title from "components/Title";
import Form from "layouts/Form";
import React from "react";

function ServicesIncludes() {
  return (
    <div className="bg-blue relative">
      <img src="images/curved-down.svg" className="w-full mt-[-2px]" alt="" />
      <div className="container py-24">
        <div className="mb-8 md:mb-12 text-white">
          <Title title="Roadmap for service" />
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-center space-y-8 lg:space-y-0 lg:space-x-10 mb-20">
          <div className="space-y-6 text-white">
            <Point point="Tracing Our Target on Web3 (Adress) and Web2 (Accounts, URL’s, etc.)" />
            <Point point="Utilizing OSINT (dApps, GitHub,Internal DB and more) " />
            <Point point="Taking official action with local authorities - Worldwide " />
            <Point point="Taking proactive legal measures " />
            <Point point="We obtain the best possible settlement for you" />
          </div>
        </div>
        <Form />
      </div>
      <img
        src="images/curved.svg"
        className="w-full relative
    bottom-[-2px]"
        alt=""
      />
    </div>
  );
}

export default ServicesIncludes;
