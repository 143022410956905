import FooterFooter from "layouts/FooterFooter";
import Navbar from "layouts/Navbar";
import React from "react";

function TermsAndConditions() {
  return (
    <>
      <div>
        <div className="bg-blue mb-9">
          <Navbar />
        </div>

        <h1 className="text-48px text-black text-center font-bold mb-9">
          TERMS AND CONDITIONS
        </h1>

        <div className="container space-y-4">
          <p className="text-xs leading-1_6 font-bold">TERMS AND CONDITIONS</p>
          <p className="text-xs leading-1_6 font-light text-black">
            Welcome to{" "}
            <a
              href="https://lionsgate.network"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              https://lionsgate.network
            </a>{" "}
            (together with its subdomains, Content, Marks and services, the
            “Website”). Please read the following Terms of Use carefully before
            using this Website so that you are aware of your legal rights and
            obligations with respect to Lionsgate LTD. ("Lionsgate", "we", "our"
            or "us"). These Terms and Conditions (“T&C”) are a legally binding
            agreement between you, the person or entity that will be accessing
            or using our Website and/or the services, including certain recovery
            service (“The Services”) by our company, (referenced below as “You”
            or “Your”) and Lionsgate. (“Company” or “We” or “Us”), with respect
            to the use of the Website and/or The Services. The Website is
            intended to display information about Lionsgate and its activities,
            including using its products and/or services, and in addition create
            a platform for users to contact Lionsgate.{" "}
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            By using the Website and/or The Services, You accept these T&C
            hereof. Lionsgate reserves the right, in its sole discretion, to
            modify these T&C at any time by posting the modified provisions on
            the Website, and you shall be responsible for reviewing and becoming
            familiar with any such modifications. Please note that any such
            modifications shall become effective immediately upon posting. If
            you do not agree to these T&C, you should not use or immediately
            refrain from using the website and\or The Services.{" "}
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            Background. The Website is intended to provide you with information
            related to our products and services, and enable you to contact us
            via the Website and its features.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            <span className="font-bold">Modification.</span> We reserve the
            right, at our discretion, to change these Terms at any time. Such
            change will be effective immediately, and your continued use of the
            Website thereafter means that you accept those changes.{" "}
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            <span className="font-bold"> Ability to Accept Terms.</span> The
            Website is only intended for individuals aged eighteen (18) years or
            older. If you are under eighteen (18) years please do not visit or
            use the Website.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            <span className="font-bold">Website Access.</span> For such time as
            these Terms are in effect, we hereby grant you permission to visit
            and use the Website, provided that you comply with these Terms and
            applicable laws.{" "}
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            <span className="font-bold">Restrictions.</span> You shall not: (i)
            copy, distribute or modify any part of the Website without our prior
            written authorization; (ii) use, modify, create derivative works of,
            transfer (by sale, resale, license, sublicense, download or
            otherwise), reproduce, distribute, display or disclose Content
            (defined below), except as expressly authorized herein; (iii)
            disrupt servers or networks connected to the Website; (iv) use or
            launch any automated system (including without limitation, "robots"
            and "spiders") to access the Website; and/or (v) circumvent, disable
            or otherwise interfere with security-related features of the Website
            or features that prevent or restrict use or copying of any Content
            or that enforce limitations on use of the Website.{" "}
          </p>
          <p className="text-xs leading-1_6 font-bold text-black">
            Use of Content.
          </p>
          {/* <div> */}
          <p className="text-xs leading-1_6 font-light text-black">
            6.1 When You use our Website and/or The Services, You represent,
            warrant and covenant that the information You provide Us is
            accurate, complete, and current at all times. It is hereby clarified
            that typing false information, including false personal information,
            of any kind, is strictly prohibited, constitutes a civil tort and
            even a criminal offense, and the perpetrator may face criminal
            and/or civil legal actions, including tort claims for damages caused
            to other users, third parties, Lionsgate and/or anyone on its
            behalf.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            6.2 You agree to accept responsibility for any and all activities or
            actions in relation to Your use of our Website and/or The Services.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            6.3 Your use of our Website and/or The Service is at your own
            discretion and at your sole risk and you will be solely responsible
            or liable for any loss of data, damage or harm to any of your
            devices used to use or access the Website and/or The Services
            including our Apps or company’s features. The information obtained
            by using the Website and/or The Services is provided without
            warranties of any kind, whether express or implied, including, but
            not limited to, implied warranties of merchantability, fitness for a
            particular purpose, title, non-infringement of any right, rule
            regulation or law, or any warranty whatsoever. Lionsgate , its
            subsidiaries and its affiliates do not warrant that: a) the Website
            and/or The services will function uninterrupted, secure or available
            at any particular time or location; b) any errors or defects will be
            corrected; c) the results of{" "}
            <span className="font-bold">
              using the Website and/or The Services will meet your requirements.
              Additionally, data or content uploaded to the Website and/or The
              Services may be lost, damaged or otherwise unrecoverable.
            </span>
          </p>
          {/* </div> */}
          <p className="text-xs leading-1_6 font-bold text-black">
            Prohibited use.
          </p>
          {/* <div> */}
          <p className="text-xs leading-1_6 font-light text-black">
            7.1 You hereby undertake that You will not, and will not permit or
            authorize third parties to use the Website and/or The Services in
            any way that: (i) is defamatory, abusive, harassing, threatening,
            racially, or constitute an invasion of a right of privacy of another
            person, or otherwise offensive, violent, vulgar, obscene, or
            otherwise harms or can reasonably be expected to harm any person or
            entity; (ii) is illegal or encourages or advocates illegal activity;
            (iii) post or transmit any communication or solicitation designed or
            intended to obtain private information from any third party; (iv)
            contain viruses, trojan horse, worm, or any other computer programs
            designed to interrupt, destroy, or limit the functionality of the
            Website and/or The Services, or any system, computer software,
            hardware or telecommunications equipment; (v) use a false identity
            or impersonate another person; (vi) violate or infringing of any
            rights (including, without limitation, privacy right, copyright, or
            other intellectual property rights) of any third party; or (vii)
            violate these T&C and/or our Privacy Policy and any applicable
            local, state, national or international law or regulation.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            7.2 In addition, you will not (directly or indirectly, whether for
            consideration or not), and will not permit or authorize third
            parties to: (i) take any action intended to circumvent or disable
            the operation of any security feature or measure of the Website
            and/or The Services; (ii) publish, distribute, sell, disclose,
            market, sublicense, rent, lease, display, provide, transfer or make
            available any Company’s Content and/or the Website and/or The
            Services, or any portion thereof, to any third party; (iii) reverse
            engineer, disassemble, enhance, or otherwise modify or use the
            source code, object code or any other code, configuration, model,
            language etc. of the Website and/or The Services, or any part
            thereof (including those owned by third parties who have permitted
            their use by Lionsgate within its Website and/or The Services, as
            applicable); (iv) modify, reproduce, or create derivative works from
            the Website and/or The Services, or any part thereof; (v) access or
            use the Website and/or The Services via automated means, including
            by crawling, scraping, caching, bots or otherwise (except as may be
            the result of standard search engine protocols or technologies used
            by a search engine with our express consent).{" "}
          </p>
          {/* </div> */}
          <p className="text-xs leading-1_6 font-bold text-black">
            Intellectual Property; Company’s & Users Content.
          </p>
          {/* <div> */}
          <p className="text-xs leading-1_6 font-light text-black">
            8.1 Lionsgate retains all rights, titles, and interests in and to
            the Website and/or The Services (including all related intellectual
            property rights). Subject to these T&C, Lionsgate hereby grants You
            a limited, personal, non-transferable, non-exclusive,
            non-assignable, permission to use the Website (and/or The Services),
            provided that You will not (directly or indirectly, whether for
            consideration or not) alter, modify, reverse engineer, disassemble,
            enhance, or otherwise use any part of the Website and/or The
            Services, including the source code, object code, integration code
            or any other code, configuration, model, including any language
            and/or interaction model definitions that are automatically
            generated by Lionsgate , and all of the above is an integral part of
            Lionsgate ’s intellectual property rights.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            8.2 All photos, texts, scripts, designs, graphics, logos, audios,
            videos, songs, interactive features, interfaces, software, code,
            trademarks, service marks, trade names and other content used,
            displayed, included, incorporated, uploaded, posted or published by
            Lionsgate as part of the Website and/or The Services, are the sole
            property of Lionsgate and/or their licensors, which expressly
            allowed Company to make use of them (“Company's Content”), and
            subject to copyright and other intellectual property rights under
            applicable laws, and You may not use, download, distribute and/or
            copy them, in whole or in part, without the prior written approval
            of Lionsgate . For the sake of clarity, You may not copy, reproduce,
            modify, publicly display, publicly perform, publish, distribute,
            sell, license, rent, transfer, reproduce, create derivative works
            based on, or exploit in any way, Lionsgate 's Content or any part
            thereof.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            8.3 If You believe in good faith that any material or content made
            available on or through the Website and/or The Services, has been
            used or exploited in a manner that infringes and/or violates your
            rights, including intellectual property rights, please send us
            prompt written notice thereof via e-mail (at:
            info@lionsgate.network, while specifying the exact location of the
            infringing material, relevant details of the nature of the
            infringement etc.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            8.4 You can contact Us by providing contact information and a
            description of your application/request (“Content”). You are
            responsible for the Content that You provide Us, including its
            legality, reliability, and appropriateness. By providing Us the
            Content, You represent and warrant that: (i) the Content is yours
            and/or You have the right to use it and the right to grant Us the
            rights to use it as described in these T&C and/or our Privacy Policy
            and (ii) that the Content does not violate any privacy rights,
            publicity rights, copyrights, trademarks, contract rights or any
            other rights of any person or entity. You retain any and all of Your
            rights to any Content You submit on our Website. We take no
            responsibility and assume no liability for Content You or any
            third-party provide Us. However, by providing Us the Content, you
            hereby grant Us the right and permission to use, modify, publicly
            display that Content, including in order to use our best endeavors
            to assist you with your application/request. Lionsgate has the
            right, but not the obligation, to monitor, edit or delete all
            Content provided by You at any time.{" "}
          </p>
          {/* </div> */}
          <p className="text-xs leading-1_6 font-bold text-black">
            General Terms Of Use.
          </p>
          {/* <div> */}
          <p className="text-xs leading-1_6 font-light text-black">
            9.1 Lionsgate reserves the right to change, suspend, take offline or
            discontinue its Website and/or The Services, at its sole discretion,
            at any time and without notice or liability.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            9.2 You are solely responsible for the activity that occurs in
            relation to the Website and/or The Services by You or on Your
            behalf. You must notify Lionsgate immediately of any breach of
            security or unauthorized use of the Website and/or The Services.
            Lionsgate will not be liable for any losses caused by any use of the
            Website and/or The Services.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            9.3 You are responsible for obtaining and maintaining any equipment,
            hardware, software or ancillary services required to access and use
            the Website, and for any fees charged by third parties in connection
            therewith, as necessary, and Lionsgate has no responsibility or
            obligation in connection therewith.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            9.4 If You send Lionsgate any feedbacks or suggestions regarding the
            Website and/or The Services, You acknowledge that Lionsgate may use
            them at its sole discretion, without any obligation to compensate
            You in any manner for such feedbacks or suggestions.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            9.5 Lionsgate is not obligated whatsoever to accept you as client
            and to provide you The Services, even if you pass the Recovery
            Wizard successfully in our Website. You shall not have any claims
            about such consideration and Lionsgate keeps itself the sole opinion
            in that matter.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            9.6 The use of any personal information provided by you and/or
            accumulated about you when using Our Website and/or The Services, as
            applicable, shall be in accordance with our Privacy Policy., which
            is an integral part of these T&C.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            9.7 It is hereby clarified that the Website and/or The Services may
            contain any links to other third parties' websites, applications or
            features and/or other platforms, or referrals to certain third
            parties’ products, content or services that are not owned or
            controlled by Lionsgate and that Lionsgate has no control over them.
            If You choose to visit such third party’s websites, applications or
            features and/or other platforms, or use its products or services,
            please be aware that such third party’s own terms of use and privacy
            policy will apply and govern Your activities. Lionsgate does not
            make any representation or warranty whatsoever regarding such third
            party's websites, applications, features, other platforms services,
            products or content. Lionsgate also does not endorse and cannot
            ensure that You will be satisfied with any content, products or
            services that You accessed, purchased or download from such other
            third parties, and is not responsible or liable in any manner for
            Your interaction with such third parties.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            9.8 Third party’s information and/or services, if provided through
            the Website and/or The Services, are not controlled by Lionsgate and
            it does not endorse any such third party’s information and/or
            services and in no event shall Lionsgate be liable for the
            aforementioned.
          </p>
          {/* </div> */}
          <p className="text-xs leading-1_6 font-bold text-black">
            Termination.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            Lionsgate may terminate or suspend the Website and/or The Services
            (or any part thereof) immediately, without prior notice or liability
            and on its sole discretion. All the provisions of these T&C which by
            their nature should survive termination (including, without
            limitation, ownership provisions, warranty disclaimers,
            indemnification obligations and limitations of liability) shall
            remain in full force and effect following termination thereof.
            Termination of these T&C shall not relieve you from any obligation
            arising or accruing prior to such termination or limit any liability
            which You otherwise may have to Lionsgate.
          </p>
          <p className="text-xs leading-1_6 font-bold text-black">
            Warranty And Disclaimer.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            You expressly acknowledge and agree that your use of the website
            and\or the services is at your sole risk and that the entire risk as
            to satisfactory quality, performance, accuracy and effort is solely
            with you. The website and\or The Services are published without
            warranty of any kind. Lionsgate does not warrant that your use of
            the website and\or The Services will be uninterrupted, error-free or
            will meet your specific requirements. Lionsgate makes no warranty or
            representation, either express or implied, regarding the website
            and\or The Services, the content and your use thereof, including,
            but not limited to, any implied warranties of fitness for a
            particular purpose, accuracy, availability, security, compatibility,
            non-infringement or completeness of responses, results and lack of
            negligence.
          </p>
          <p className="text-xs leading-1_6 font-bold text-black">
            Limitation Of Liability.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            Lionsgate shall not bear any liability, whether express or implied,
            in connection with the website and\or The Services, including any
            content that is presented through it. You agree that you are fully
            and exclusively liable for any use you and/or anyone on your behalf
            makes on the website and\or The Services, including any content
            appearing on them, and that you are aware that Lionsgate is not and
            will not be liable for it, either directly or indirectly.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            In no event shall Lionsgate , its shareholders, directors, officers
            or employees, be liable for any loss, damage, or harm, including
            personal injury, or any special, incidental, punitive, consequential
            or indirect damages, including labor costs, loss of data, loss of
            goodwill, loss of profits, loss of savings, loss of business
            information, or loss of use or other pecuniary loss, in connection
            with or arising out of these T&C, the website and\or The services
            and/or your use of or inability to use them, however caused, on any
            theory of liability, regardless of whether Lionsgate has been
            advised of the possibility of such damages. Without derogating from
            the above, in no case shall the aggregate liability of Lionsgate and
            its shareholders, directors, officers and employees under these T&C
            or arising out of or otherwise related to your use of the website
            and\or The Services exceed one hundred us dollars.{" "}
          </p>
          <p className="text-xs leading-1_6 font-bold text-black">
            Indemnification.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            Upon its first demand, You will indemnify and hold Lionsgate , its
            shareholders, directors, officers and employees, harmless from any
            claim, liability, cost, loss, damage and expense (including
            reasonable legal fees) caused due to Your access and use of the
            Website and/or The Services in violation of these T&C or in
            violation or infringement of any rights (including, without
            limitation, privacy right, copyright, or other intellectual property
            rights) of any third party and applicable law.
            <br /> Hours Of Operation.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            We intend that the Website will operate 24 hours a day, except in
            cases where a shutdown is required for maintenance and/or for any
            other reason, all in accordance with Our full and exclusive
            discretion.
          </p>
          <p className="text-xs leading-1_6 font-bold text-black">
            Miscellaneous.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            These T&C and its performance shall be governed exclusively by the
            laws of the State of Israel, without regard to conflict of law’s
            provisions that would result in the application of the laws of any
            other jurisdiction. The parties hereto submit the exclusive
            jurisdiction to the courts of Tel Aviv.{" "}
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            These T&C and our Privacy Policy constitute the entire agreement
            between You and Lionsgate with respect to the access and/or use of
            the Website and/or The Services (except to the extent that there is
            a specific agreement for those services officially published by
            Lionsgate ), and supersede all prior or contemporaneous
            understandings regarding such subject matter.{" "}
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            Lionsgate reserves the right to update these T&C from time to time,
            with or without notice, and will post its updated T&C on its
            Website. Your continued use of our Website and/or The Services, as
            applicable, will be subject to the then-current T&C. If any
            modification is unacceptable to You, You may cease using the Website
            and/or The Services. If You do not cease using the Website and/or
            Services, You will be deemed to have accepted those modifications.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            Lionsgate may assign at any time any of its rights and/or
            obligations hereunder to any third party without requiring Your
            consent.{" "}
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            The failure of Lionsgate to enforce any right or provision in these
            T&C will not constitute a waiver of such right or provision unless
            acknowledged and agreed by Lionsgate in writing.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            These T&C will also govern any future upgrades or updates or new
            releases provided by Lionsgate in connection with the Website and/or
            The Services, unless any such upgrades or updates are accompanied by
            a separate set of terms and conditions, in which case the terms of
            that upgraded or updated T&C will govern.{" "}
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            In the event that a court of competent jurisdiction finds any
            provision of these T&C to be illegal, invalid or unenforceable, the
            remaining provisions will remain in full force and effect.
          </p>
          <p className="text-xs leading-1_6 font-light text-black">
            If You have any questions or queries about these T&C or our services
            in general, please do not hesitate to contact us via email at:
            info@lionsgate.network.
          </p>
        </div>

        <div className="container">
          <p className="text-xs leading-1_6 font-bold text-black my-16">
            Last updated: October 09, 2022
          </p>
        </div>

        <div className="container">
          <div className="flex flex-col sm:flex-row  items-center sm:items-start flex-nowrap justify-between">
            <div>
              <p className="text-sm lg:text-xl leading-1_6 font-light text-black my-4">
                © 2022 Lionsgate
              </p>
            </div>

            <div>
              <p className="text-sm lg:text-xl leading-1_6 font-light text-black my-4 mb-8">
                Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*
      <div className="bg-blue pt-10">
        <FooterFooter />
      </div>
        */}
    </>
  );
}

export default TermsAndConditions;
