import React from "react";
import ScrollAnimation from "./ScrollAnimation";

function Title({
  title,
  className,
  alignment = "center",
  triggerOnce = false,
  threshold,
}) {
  return (
    <ScrollAnimation
      threshold={threshold}
      triggerOnce={triggerOnce}
      initial={{ y: -50, opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
      }}
    >
      <h1
        className={`font-bold text-xl sm:text-2xl lg:text-3xl xl:text-5xl uppercase ${className}`}
        style={{ textAlign: alignment }}
      >
        {title}
      </h1>
    </ScrollAnimation>
  );
}

export default Title;
