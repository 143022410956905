import FooterFooter from "layouts/FooterFooter";
import Navbar from "layouts/Navbar";
import React from "react";

function PrivacyPolicy() {
  return (
    <>
      <div>
        <div className="bg-blue mb-9">
          <Navbar />
        </div>

        <h1 className="text-48px text-black text-center font-bold mb-9">
          PRIVACY POLICY
        </h1>

        <div className="container space-y-4">
          <p className="text-xs leading-1_6 text-black font-light">
            Lionsgate Ltd. (referenced below as “Lionsgate”, “Company”, “We”, or
            “Our”, as applicable), respects your privacy and is committed to
            protect the Personal Information that you share with Us on Our
            website (“Website”) within our Website. This policy describes the
            scope of Personal Information collection on Our Website and the
            usage of it.
            <br />
            By using Lionsgate's Website and/or other services, you acknowledge
            that you have reviewed both our T&C and this Privacy Policy and
            agree to be bound by them. Please be aware that this Privacy Policy
            does not apply to the practices of any third parties to whom The
            Company may disclose Personal Information (as defined below)
            pursuant to the terms of this Privacy Policy. <br />
            This Privacy Policy may be occasionally updated. If we make any
            substantial changes in the manner we use your Personal Information,
            then we will provide a proper notice within this Privacy Policy.
          </p>

          <p className="text-xs leading-1_6 text-black font-bold">
            {" "}
            SCOPE OF THIS PRIVACY POLICY
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            This Privacy Policy applies to you and Us, in relation to our
            Website and/or other services, and it governs any and all data
            collection and usage by Us in compliance with applicable
            regulations. Through the use of this Website and/or other services,
            you are therefore consenting to the data collection procedures
            expressed in this Policy. We gather various types of information
            from our users, as explained in more detail below, and we use this
            information internally in connection with our Website and/or other
            services, including to personalize, provide, and improve our
            services, to allow you to set up a user account and profile, to
            contact you and allow other users to contact you, if applicable, and
            to analyze how you use our services. In certain cases, we may also
            share some information with third parties, but only as described
            below. Please note that this Privacy Policy does not govern the
            collection and use of information by companies that We do not
            control or managed by Us. If you visit a website that is made
            available via our Website and/or other services or link to, be sure
            to review its privacy policy before providing such platform with any
            information.
          </p>

          <p className="text-xs leading-1_6 text-black font-bold">
            {" "}
            DATA AND PERSONAL INFORMATION WE COLLECT{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            While using the Website and/or other services, including for
            contacting Us, you may be required to provide and\or choose to share
            with us certain information, including Personal Information and
            Anonymous Data (as defined hereafter).
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Personal Information - means any information that may be used to
            identify an individual, either alone or in combination with other
            information, including, for example, your contact information such
            as your full name, email address, telephone, physical address,
            wallets number/ hash as well as any other non-public information
            about you that is associated with or linked to any of the foregoing
            data.{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Anonymous Data - means data that is not associated with or linked to
            your Personal Data; Anonymous Data does not, by itself, permit the
            identification of individual persons.{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Lionsgate may collect Personal Data, Anonymous Data and a few other
            categories of information, from a few different source, in
            accordance with your use of Our Website and/or other services,
            as described below.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Contact and identifiable information – Full name, e-mail address,
            education, professional experience, resume wallets number/ hash and
            workplace;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Account information, such as online password and other log-in
            details used to access the Website, including Unique ID etc.;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Comments, recommendations, reviews, feedback, responses and/or
            derived correspondences with the Company on Our Website and/or other
            services; Anonymous Data;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Any information provided by you and/or collected when you use Our
            Website and/or other services.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Other Information – Lionsgate We may use Google Analytics to collect
            user interaction data of Our website users, in order to adjust your
            browsing and usage experience on our website and to derive
            statistical insights in those aspects. You can find further
            information on the Google website at
            https://www.google.com/intl/en/policies/technologies/ads/. Lionsgate
            may also collect and use other analytics tools to collect
            information about the use of our Website and types of technical data
            in the context of the use of our Website, including information
            about your computer or mobile device or any other device and system,
            software, location data, time zone, Internet Protocol (IP) address,
            language preference etc. You can read more about how Google uses
            your Personal Information here:
            https://policies.google.com/privacy?hl=en. You can also opt-out of
            Google Analytics here: https://tools.google.com/dlpage/gaoptout.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Certain content on our Website and/or other services may be served
            by third-parties. Please note that these companies may also use
            cookies or other similar technologies to collect various data about
            you. As stated above, data collection and any use by those
            third-parties is subject to their privacy policies. It is the intent
            of this Website and/or other services to collect and use personal
            information only for the purpose for which it was requested and or
            transmitted, and any additional uses specifically provided for on
            this Privacy Policy.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            You acknowledge that you are not required under applicable law to
            provide us with any of your Personal Information, and that if you
            provide such information, it’s at your own free will and consent.
            Without derogating from the above, if you choose not to provide us
            with certain information, this may limit your usage of the Website
            and/or other services.
          </p>

          <p className="text-xs leading-1_6 text-black font-bold">
            {" "}
            PURPOSE OF DATA COLLECTING AND PROCESSING{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Generally, Lionsgate use the information gathered to provide,
            improve, and develop our Website and/or other services, to
            communicate with you, to offer you better services, and to protect
            us and our users. When you interact with or make use of our Website
            and/or other services, Lionsgate use a variety of technologies to
            process the information that We collect about you for various
            reasons.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            The purposes of our collection and processing of information in the
            context of our Website and/or other services are:
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            To operate our Website and/or other services and to our users;{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            To facilitate the creation of your account and securing it;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            To support our Website's and/or other services users, address their
            applications, inquiries, questions, requests, comments, responses
            etc.;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            To identify you as a user of our Website and/or other services;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            To send operational messages and alerts to users by various means,
            including by e-mail, text and/or push messages etc;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            To send newsletters, surveys, offers, and other promotional
            materials and for other marketing purposes, subject to obtaining
            your explicit and informed consent;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            To operate, evaluate, develop, manage, improve and enrich the
            information and various services offered by the Website, and create
            new services and content that meet the requirements and expectations
            of users as well as modify or cancel existing services;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            To prevent illegal and/or unauthorized use of our Website, and to
            comply with and enforce applicable legal requirements, relevant
            industry standards and policies, including this Privacy Policy and
            our T&C;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            To increase and maintain the safety and security of our Website
            and/or other services;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            To fulfil contractual obligations with third parties;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Find and prevent fraud;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            For any other purpose required or permitted by any applicable law or
            specified in the Privacy Policy;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            LEGAL BASIS FOR PROCESSING
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            In addition to your consent to the collection and processing of
            information as set forth herein, our collection and processing of
            information, including Personal Information, is based on the
            following:{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            To the extent that Lionsgate use the information, including Personal
            Information, to provide various services and/or support and/or to
            interact with our Website users, including addressing users'
            applications, inquiries, questions, requests, comments and
            responses, the basis for our collection of data is the users consent
            in this Privacy Policy and our legitimate interest to conduct an
            effective interaction with Our users.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            To the extent that Lionsgate uses the information to analyse users'
            interaction with our Website, the basis for our collection of data
            is our legitimate interest to study the use of our Website and the
            behavior of users in the context of their use of our Website.
          </p>

          <p className="text-xs leading-1_6 text-black font-bold">
            {" "}
            INFORMATION SHARING AND DISCLOSURE
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Lionsgate is committed to protecting your privacy and will not sell,
            rent, trade, or otherwise share or disclose your Personal
            information, unless otherwise permitted under the following terms
            and/or circumstances:{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            When We have your consent to share such information;{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            When We need to share your information to provide you with the
            relevant information that you have requested as part of our
            contractual relationship;{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            For our service providers. They may use your information to perform
            services for us and may have access to, store and process your
            Personal Information to provide services on our behalf;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            For our subsidiaries and affiliates, for the purposes the
            information was provided by you or as reasonably necessary for our
            internal administrative and business purposes;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            For the purpose of analysis and statistics, provided that such
            disclosure is done anonymously and is non-personal identifiable;{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            In the event of sale or transfer, in whole or in part, of the
            Company’s or any of its affiliate’s shares or assets, change of
            control and in cases of mergers or in case of negotiations with
            respect to the foregoing;{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            In the event that the Company deems that the disclosure of the
            information is necessary in order to prevent serious damage to the
            bodies or property of the users or of any other party;
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            If required to do so by applicable law, or in the good-faith belief,
            that (i) disclosing such information is necessary to comply with any
            applicable law or respond to any court order, search warrant or
            equivalent, or where in reasonable belief an action is needed in
            order to protect the personal safety of any individual; and (ii)
            such disclosure is appropriate or necessary in order to enforce the
            T&C, take precautions against liabilities, investigate and defend
            against any third party claims or allegations, assist government
            enforcement agencies, protect the security or integrity of our
            Website and/or other services, and servers and protect our rights
            and property. Other than as described above, any other transfer of
            your Personal Information to third parties will be subject to your
            explicit and informed consent.
          </p>

          <p className="text-xs leading-1_6 text-black font-bold"> COOKIES </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            We may use industry-wide technologies such as cookies (a string of
            information that can be stored on your device, if your device is set
            to permit storage of cookies), or other similar tracking
            technologies, as described in this Privacy Policy above, which store
            certain local information on your device to help Lionsgate learn
            more about how you use our Website and/or other services, and
            enhance your experience. You may adjust your device or operating
            system settings to limit this tracking or to decline cookies, but by
            doing so, you may not be able to use certain features on Our Website
            and/or other services. It is possible to prohibit and/or delete the
            cookies, inter alia, via settings option of your device.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            CCPA Privacy Rights (Do Not Sell My Personal Information)
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Under the CCPA, among other rights, California consumers have the
            right to:
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Request that a business that collects a consumer's personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Request that a business delete any personal data about the consumer
            that a business has collected.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Request that a business that sells a consumer's personal data, not
            sell the consumer's personal data.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>

          <p className="text-xs leading-1_6 text-black font-bold">
            {" "}
            GDPR Data Protection Rights
          </p>
          <div>
            <p className="text-xs leading-1_6 text-black font-light">
              {" "}
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
            </p>
            <p className="text-xs leading-1_6 text-black font-light">
              {" "}
              The right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service.
            </p>
            <p className="text-xs leading-1_6 text-black font-light">
              {" "}
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete.
            </p>
            <p className="text-xs leading-1_6 text-black font-light">
              {" "}
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions.
            </p>
            <p className="text-xs leading-1_6 text-black font-light">
              {" "}
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </p>
            <p className="text-xs leading-1_6 text-black font-light">
              {" "}
              The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions.
            </p>
            <p className="text-xs leading-1_6 text-black font-light">
              {" "}
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </p>
            <p className="text-xs leading-1_6 text-black font-light">
              {" "}
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us at:
              info@lionsgate.network.
            </p>
          </div>

          <p className="text-xs leading-1_6 text-black font-bold">
            {" "}
            Children's Information
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Lionsgate does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            DATA RETENTION & SECURITY
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            We may retain all the information We collect for as long as required
            for the provision of our information and/or services in relation to
            our Website and/or other services. In addition, Lionsgate may also
            retain such information for as long as required to comply with our
            legal or business requirements or obligations, to resolve disputes,
            to enforce our T&C, all as permitted under any applicable laws.{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Without derogating from the above, Lionsgate stores Personal
            Information if it is necessary to fulfill the purpose for which the
            information has been collected. This means that We delete your
            Personal Information when such information is no longer necessary.
            Statistics and analysis which have been anonymised, and Anonymous
            Data may be saved thereafter.{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            We will take commercially reasonable measures in providing secure
            transmission of your information across the internet to our servers,
            and in securing the data which relates your information while at
            rest, as applicable.
          </p>

          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            We may use cloud service providers for our servers and\or storage,
            which are located within the EU/EEA, or in a location where the EU
            has determined that it is acceptable to store such information in
            accordance with an adequacy decision. In such cases, we rely on the
            security measures taken by such cloud service providers for the
            physical security, the integrity, redundancy and the data protection
            of the data which relates your information. You acknowledge and
            agree to such transfer and/or storage of Personal Information, and
            the Company undertakes that it will take the necessary measures to
            ensure a sufficient level of protection in accordance with
            applicable privacy laws.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            However, you should be aware that there is always some risk involved
            in transmitting information over the internet and that there is also
            some risk that others could find a way to thwart our and/or the
            cloud service provider security systems. As a result, while we
            strive to protect your information, we cannot ensure or warrant the
            security and privacy of your Personal Information or other content
            you may have provided while using our Website and/or other services,
            and you do so at your own risk. We encourage you to exercise
            discretion regarding the Personal Information you choose to
            disclose.
          </p>

          <p className="text-xs leading-1_6 text-black font-bold">
            {" "}
            DATA SUBJECT RIGHTS{" "}
          </p>

          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            You may at any time request a review, rectification and/or the
            erasure of your Personal Information. Please note however that
            deletion could mean that the Company will not be able to process
            your applications/inquiries/requests and/or to provide you any
            services including in relation to the Website. You also have the
            right to request a copy of your Personal Information from our
            records and/or to request a limitation of the use of your Personal
            Information or the termination of use of your Personal Information.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            If you wish to review, modify or delete your Personal Information,
            you can do so within the settings of your account, when possible, or
            you can send Us an e-mail with your request to:
            info@lionsgate.network, and We will make reasonable efforts to do so
            within a reasonable period of time, and pursuant to any applicable
            privacy laws.{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Note that unless you instruct us otherwise, we may retain and use
            your Personal Information for as long as reasonably required for the
            purposes of which such Personal Information was collected, including
            without limitation, for future analytics and analysis, comply with
            our legal or business requirements or obligations, to resolve
            disputes, to enforce our T&C, all as permitted under any applicable
            privacy laws.{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            Aggregate and/or anonymous information, including Anonymous Data
            derived from your use of our Website and/or other services, may
            remain on our servers indefinitely
          </p>

          <p className="text-xs leading-1_6 text-black font-bold">
            {" "}
            PRIVACY INQUIRIES{" "}
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            If you have any additional questions or queries about this Privacy
            Policy or regarding the way We collect or process Personal
            Information, you may contact Us via e-mail at:
            info@lionsgate.network.
          </p>
          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            If you are a resident of the EU/EEA and have a complaint regarding
            the processing of your Personal Information by the Lionsgate, you
            are entitled to report such dissatisfaction to the applicable
            supervisory authority in your country of residence.{" "}
          </p>

          <p className="text-xs leading-1_6 text-black font-light">
            {" "}
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collected in Lionsgate. This policy is not
            applicable to any information collected offline or via channels
            other than this website. Our Privacy Policy was created with the
            help of the Free Privacy Policy Generator.
          </p>
        </div>

        <div className="container">
          <p className="text-xs leading-1_6 font-bold text-black my-16">
            {/* Last updated: October 09, 2022 */}
          </p>
        </div>

        <div className="container">
          <div className="flex flex-col sm:flex-row  items-center sm:items-start flex-nowrap justify-between">
            <div>
              <p className="text-sm lg:text-xl leading-1_6 font-light text-black my-4">
                © 2022 Lionsgate
              </p>
            </div>

            <div>
              <p className="text-sm lg:text-xl leading-1_6 font-light text-black my-4 mb-8">
                Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*
      <div className="bg-blue pt-10">
        <FooterFooter />
      </div>
        */}
    </>
  );
}

export default PrivacyPolicy;
