import CompressedSocialIcon from "components/CompressedSocialIcon";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
  FaTelegramPlane,
} from "react-icons/fa";
import { GrReddit } from "react-icons/gr";

function CompressedSocialIcons() {
  return (
    <div className="flex items-center space-x-4 lg:space-x-4 xl:space-x-6 justify-center">
      <CompressedSocialIcon
        href="https://www.facebook.com/LionsGateWizard/"
        target="_blank"
        Icon={() => (
          <FaFacebook className="text-sm lg:text-base xl:text-lg text-blue" />
        )}
      />
      <CompressedSocialIcon
        href="https://twitter.com/_Lionsgate_"
        target="_blank"
        Icon={() => <FaTwitter className="text-20px text-blue" />}
      />
      <CompressedSocialIcon
        href="https://www.linkedin.com/company/lions-gate/?viewAsMember=true"
        target="_blank"
        Icon={() => (
          <FaLinkedinIn className="text-sm lg:text-base xl:text-lg text-blue" />
        )}
      />
      {/* <SocialIcon
      href="/"
      target="_blank"
      Icon={() => (
        <FaMediumM className="text-base lg:text-xl xl:text-2xl text-blue" />
      )}
    /> */}
      <CompressedSocialIcon
        href="https://t.me/+h-NhDmCil8AwM2Ux"
        target="_blank"
        Icon={() => (
          <FaTelegramPlane className="text-sm lg:text-base xl:text-lg text-blue" />
        )}
      />
      {/* <SocialIcon
      href="/"
      target="_blank"
      Icon={() => (
        <HiMail className="text-base lg:text-xl xl:text-2xl text-blue" />
      )}
    /> */}
      <CompressedSocialIcon
        href=""
        target="_blank"
        Icon={() => (
          <FaYoutube className="text-sm lg:text-base xl:text-lg text-blue" />
        )}
      />
    </div>
  );
}

export default CompressedSocialIcons;
