import OutsideClickDetector from "hooks/OutsideClickDetector";
import React, { useRef } from "react";
import { useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import Checkbox from "./Checkbox";

function Select({ id, name, options, placeholder, onChange, value }) {
  const selectRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = OutsideClickDetector(() => setIsOpen(false));

  const selectOption = (e) => {
    setIsOpen(false);
    onChange(e);
  };
  return (
    <div className="relative" ref={menuRef}>
      <div
        onClick={() => setIsOpen((val) => !val)}
        ref={selectRef}
        name={name}
        className="form-input hide-icon cursor-pointer flex items-center justify-between space-x-4 "
        id={id}
      >
        <p className="font-light font-sm text-xs lg:text-sm opacity-40 ellipsis select-none pointer-events-none">
          {value === "" ? placeholder : value}
        </p>

        <button className="min-w-[1.8em] h-[1.8em] flex items-center justify-center text-[8px] xl:text-xs text-white rounded-full bg-blue">
          <AiFillCaretDown
            className={`${
              isOpen ? "rotate-180" : "rotate-0"
            } transition-all duration-[.24s]`}
          />
        </button>
      </div>

      <div
        className={`absolute top-[120%] left-0 w-full bg-white rounded-2xl py-2 transition-all duration-[.24s] z-50 overflow-hidden ${
          isOpen
            ? "translate-y-[0px] opacity-100 pointer-events-auto"
            : "translate-y-[-10px] opacity-0 pointer-events-none"
        }`}
      >
        {options &&
          options.map((item, index) => (
            <Checkbox
              onChange={selectOption}
              type="radio"
              name="aaa"
              key={index}
              id={item.id}
              title={item.title}
              selected={item.selected}
            />
          ))}
      </div>
    </div>
  );
}

export default Select;

{
  /* <div className="relative">
<select
  onChange={(e) => setValue(e.target.value)}
  ref={selectRef}
  name={name}
  className="form-input hide-icon cursor-pointer"
  placeholder="select"
  id={id}
  // value={placeholder}
>
  <option
    className="font-medium text-xs lg:text-sm"
    value={placeholder}
    disabled
    hidden
    selected
  >
    {placeholder}
  </option>

  {options &&
    options.map((option, index) => {
      return (
        <option
          className="font-medium text-xs lg:text-sm"
          key={index}
          value={option.title}
        >
          {option.title}
        </option>
      );
    })}
</select>

<button className="w-[1.8em] h-[1.8em] flex items-center justify-center text-[8px] xl:text-xs text-white rounded-full bg-blue absolute top-1/2 -translate-y-1/2 right-5 pointer-events-none">
  <AiFillCaretDown />
</button>
</div> */
}
