import HowDoWeWorkCard from "components/HowDoWeWorkCard";
import ScrollAnimation from "components/ScrollAnimation";
import Title from "components/Title";
import React from "react";

function HowDoWeWork() {
  return (
    <div className="container">
      <div className="mb-9 md:mb-14">
        <Title triggerOnce={true} title="HOW DO WE WORK?" />
      </div>

      <ScrollAnimation
        triggerOnce={true}
        initial={{ y: 100, opacity: 1 }}
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.1 },
        }}
        className="flex
        flex-col flex-nowrap
        justify-center
        items-center
        gap-y-[50px]
         lg:block relative"
      >
        {/*
          relative
        w-full
        max-w-[500px]
        m-auto flex flex-wrap justify-center lg:max-w-none lg:grid grid-cols-2 gap-y-10 gap-x-8 lg:gap-0 lg:-mx-6 lg:-my-10
          */}
        <img
          src="images/vector-line.png"
          className="hidden lg:block absolute top-[150px] left-[202px] w-[82%] h-[50%] z-0 lg:top-[182px]"
          alt=""
        />

        <img
          src="images/mobile-vector-line.png"
          className="block absolute top-[76px] lg:hidden z-0"
          alt=""
        />

        <div className="hidden lg:flex flex-row flex-nowrap justify-evenly ">
          <HowDoWeWorkCard
            img="images/work-cards/1.png"
            count={1}
            className="lg:mx-6 lg:my-10"
            desc="You will receive a call from a Lionsgate representative to discuss your case"
          />
          <HowDoWeWorkCard
            img="images/work-cards/2.png"
            count={2}
            className="lg:mx-6 lg:my-10"
            desc="Lionsgate open an official file after gathering vital information about the case. "
          />
          <HowDoWeWorkCard
            img="images/work-cards/3.png"
            count={3}
            className="lg:mx-6 lg:my-10"
            desc="Then we forward the case to a senior analyst on our Blockchain Team to get you approved. "
          />
          <HowDoWeWorkCard
            img="images/work-cards/4.png"
            count={4}
            className="lg:mx-6 lg:my-10"
            desc="We create a plan to resolve the case."
          />
        </div>

        <div className="hidden lg:flex flex-row-reverse justify-center flex-nowrap">
          <HowDoWeWorkCard
            img="images/work-cards/5.png"
            count={5}
            className="lg:mx-6 lg:my-10"
            desc="Explore your legal options with the help of our Legal Team."
          />

          <HowDoWeWorkCard
            img="images/work-cards/6.png"
            count={6}
            className="lg:mx-6 lg:my-10"
            desc="Lionsgate communicate with the fund's holder to resolve the case or to refer it to local or national authorities."
          />
          <HowDoWeWorkCard
            img="images/work-cards/7.png"
            count={7}
            className="lg:mx-6 lg:my-10"
            desc="Lionsgate gets you the best settlement."
          />
        </div>

        <div className="flex lg:hidden flex-row flex-nowrap gap-y-8">
          <HowDoWeWorkCard
            img="images/work-cards/1.png"
            count={1}
            className="lg:mx-6 lg:my-10"
            desc="You will receive a call from a Lionsgate representative to discuss your case"
          />
          <HowDoWeWorkCard
            img="images/work-cards/2.png"
            count={2}
            className="lg:mx-6 lg:my-10"
            desc="Lionsgate open an official file after gathering vital information about the case. "
          />
        </div>

        <div className="flex lg:hidden flex-row-reverse flex-nowrap gap-y-8 ">
          <HowDoWeWorkCard
            img="images/work-cards/3.png"
            count={3}
            className="lg:mx-6 lg:my-10"
            desc="Then we forward the case to a senior analyst on our Blockchain Team to get you approved. "
          />
          <HowDoWeWorkCard
            img="images/work-cards/4.png"
            count={4}
            className="lg:mx-6 lg:my-10"
            desc="We create a plan to resolve the case."
          />
        </div>

        <div className="flex lg:hidden flex-row flex-nowrap gap-y-8">
          <HowDoWeWorkCard
            img="images/work-cards/5.png"
            count={5}
            className="lg:mx-6 lg:my-10"
            desc="Explore your legal options with the help of our Legal Team."
          />

          <HowDoWeWorkCard
            img="images/work-cards/6.png"
            count={6}
            className="lg:mx-6 lg:my-10"
            desc="Lionsgate communicate with the fund's holder to resolve the case or to refer it to local or national authorities."
          />
        </div>

        <div className="flex lg:hidden flex-row-reverse flex-nowrap gap-y-8">
          <HowDoWeWorkCard
            img="images/work-cards/7.png"
            count={7}
            className="lg:mx-6 lg:my-10"
            desc="Lionsgate gets you the best settlement."
          />
        </div>
      </ScrollAnimation>
    </div>
  );
}

export default HowDoWeWork;
