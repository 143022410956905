import React from "react";

function CompressedSocialIcon(props) {
  const { Icon } = props;
  return (
    <a
      {...props}
      className="w-8 lg:w-8 xl:w-8 h-8 lg:h-8 xl:h-8 rounded-full bg-white flex items-center justify-center"
    >
      {Icon && <Icon />}
    </a>
  );
}

export default CompressedSocialIcon;
