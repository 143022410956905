import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const INITIAL = {
  x: -100,
};

const ANIMATE = {
  x: 0,
};

function ScrollAnimation({
  children,
  initial = INITIAL,
  animate = ANIMATE,
  threshold = 0.8,
  className,
  transition,
  triggerOnce = false,
}) {
  const { ref, inView } = useInView({
    threshold,
    triggerOnce,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start(animate);
    }

    if (!inView) {
      animation.start(initial);
    }
  }, [inView]);

  return (
    <motion.div
      ref={ref}
      animate={animation}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  );
}

export default ScrollAnimation;
