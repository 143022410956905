import Navbar from "layouts/Navbar";
import React from "react";
import Form from "layouts/Form";
import FooterFooter from "layouts/FooterFooter";
import SocialIcons from "layouts/SocialIcons";

function Contact() {
  return (
    <>
      <div className="bg-blue min-h-screen pb-0 relative z-10 overflow-hidden">
        <div className="mb-4 md:mb-9">
          <Navbar />
        </div>

        <div className="container">
          <h1 className="text-48px text-white text-center font-bold mb-6 md:mb-8 xl:mb-10">
            CONTACT US
          </h1>
          <div className="flex flex-col items-center space-y-1 mb-2 md:mb-6 xl:mb-8">
            <p className="text-20px text-white font-bold">Tel Aviv</p>
            <p className="text-20px text-white font-light">
              3 Louis Pasteur, Building 13{" "}
            </p>
            <p className="text-20px text-white font-light">
              Tel Aviv-Yafo, Israel{" "}
            </p>
          </div>
          <div className="flex flex-col items-center space-y-1 mb-10 md:mb-14 xl:mb-20">
            <a
              href="tel:+442037699711"
              className="text-24px block w-fit font-bold text-white"
            >
              International Hotline +44 2037 699 711
            </a>
            <a
              href="mailto:info@lionsgate.network"
              className="text-24px block w-fit font-bold text-white"
            >
              info@lionsgate.network
            </a>
            <div
              className="mb-12 md:mt-16 xl:mb-24"
              style={{
                marginTop: "30px",
                borderRadius: "30px",
                border: "10px solid #FFFFFF",
                width: "100%",
                maxWidth: "800px",
              }}
            >
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3381.6235064675825!2d34.74996075098371!3d32.05238072779258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4cb87c12534d%3A0x8fa47cfcefc316d6!2sLouis%20Pasteur%20St%203%2C%20Tel%20Aviv-Yafo%2C%20Israel!5e0!3m2!1sen!2sus!4v1669064887557!5m2!1sen!2sus"
                height="450"
                style={{
                  border: 0,
                  borderRadius: "20px",
                  width: "100%",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          <div className="mb-8 md:mt-12 xl:mb-16">
            <Form />
          </div>

          {/*
                      <div className="mb-12 md:mb-16 xl:mb-24">
            <SocialIcons />
          </div>

            */}

          {/*
          <img
            src="images/contact-img.png"
            className="md:absolute top-[4%] right-[0%] translate-x-1/2 w-[60%] max-w-[1000px] pointer-events-none select-none -z-20 hidden md:block"
            alt=""
          />

          <img
            src="images/contact-img-mobile.png"
            className="max-w-[16rem] w-full mx-auto mb-6 block md:hidden"
            alt=""
          />
              */}
        </div>
      </div>
      <div className=" bg-blue pt-10 hidden lg:block">
        <FooterFooter />
      </div>

      <div className="lg:hidden bg-blue">
        <SocialIcons />
      </div>
      <div className=" block lg:hidden bg-blue px-20 pt-10">
        <div className="flex flex-col sm:flex-row  items-center sm:items-start flex-nowrap justify-between">
          <div>
            <p className="text-sm lg:text-xl leading-1_6 font-light text-white my-4 mb-4">
              Privacy Policy
            </p>
          </div>

          <div>
            <p className="text-sm lg:text-xl leading-1_6 font-light text-white mb-4">
              © 2022 Lionsgate
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
