import useMediaQuery from "hooks/useMediaQuery";
import FooterFooter from "layouts/FooterFooter";
import Navbar from "layouts/Navbar";

function ReferAFriend() {
  const isAbove1024px = useMediaQuery("(min-width:1024px)");

  return (
    <>
      <div className="relative  lg:min-h-[700px]">
        <Navbar className="bg-blue" />
        <div className="mb-16 sm:mb-20 xl:mb-150px">
          <div className="container pt-8 sm:pt-12">
            <h1 className="text-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-8 lg:mb-16">
              Refer a Friend
            </h1>

            <div className="lg:w-50% text-left">
              <div className="space-y-6 mb-14 md:mb-20">
                <p className="text-base lg:text-lg xl:text-xl font-light text-black">
                  The Lionsgate Refer a Friend program returns November 11,
                  2022! Refer a friend to Lionsgate and you'll win a 10% voucher
                  for our in-platform services. Your reward is a 10% discount on
                  Lionsgate services for each successful referral you make.
                  That's right! For the 10th friend who registers with
                  Lionsgate, we will waive our service fee. What are you waiting
                  for?!
                </p>
              </div>
            </div>
          </div>
        </div>

        {isAbove1024px ? (
          <>
            <img
              src="images/refer-a-friend.png"
              className="absolute top-[50px] right-[0] w-[46%] max-w-[600px] -z-50 hidden lg:block"
              alt=""
            />
          </>
        ) : (
          <>
            <img
              src="images/curved-blue.svg"
              className="-z-50 w-full mb-[-13%] hidden lg:block"
              alt=""
            />
            <div className="mt-25px pb-0px lg:py-20 relative">
              <div className="lg:mb-6">
                <div className="container w-[100%]">
                  <img
                    src="images/mobile-refer-a-friend.png"
                    className="w-full max-w-[800px] mx-auto"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="bg-blue pt-10 lg:pt-20">
        <FooterFooter />
      </div>
    </>
  );
}

export default ReferAFriend;
