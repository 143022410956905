import useMediaQuery from "hooks/useMediaQuery";
import FooterFooter from "layouts/FooterFooter";
import Navbar from "layouts/Navbar";

function AboutPage() {
  const isAbove1024px = useMediaQuery("(min-width:1024px)");

  return (
    <div className="relative">
      <Navbar className="bg-blue" />
      <div className="mb-16 sm:mb-20 xl:mb-150px">
        <div className="container pt-8 sm:pt-12">
          <h1 className="text-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-8 lg:mb-16">
            Blog
          </h1>

          <div className="lg:w-50% text-left"></div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
