import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
import Scroll from "react-scroll";
import SocialIcons from "./SocialIcons";
import CompressedSocialIcons from "./CompressedSocialIcons";

function FooterFooter({ textColor = "text-white", arrowUpShow = false }) {
  return (
    <div className="w-full  text-center md:text-left">
      <div className="container">
        <Link to="/">
          {/*
              <img
                src="images/logo.png"
                className="w-32 md:w-36 lg:w-40 xl:w-52 min-w-[128px] md:min-w-[144px] lg:min-w-[160px] xl:min-w-[208px]"
                alt=""
              />
                */}

          <p className={`text-3xl font-bold ${textColor} hidden md:block`}>
            LIONSGATE
          </p>
        </Link>

        <div className="md:flex flex-row justify-between">
          <div className="grid gap-y-0 md:gap-y-8 mt-6 sm:grid-cols-[repeat(2,1fr)] md:grid-cols-[repeat(4,1fr)] space-x-0 sm:gap-x-6 sm:gap-y-6 sm:space-x-0 xl:space-x-10 mb-5 md:mb-20">
            <div className="hidden md:flex flex-col justify-between items-center md:items-start sm:mr-8 min-w-none lg:min-w-[320px]">
              <div>
                <p className="text-base font-light tracking-[.2px] xl:text-lg text-white mt-4">
                  Lionsgate is a registered LLC in Israel with a public business
                  address.
                </p>
                <p className="text-base font-semibold xl:text-lg text-white mb-5">
                  International Hotline +44 2037 699 711 <br />
                  info@lionsgate.network
                </p>
              </div>

              <div className="flex justify-center space-x-2 w-100%">
                <CompressedSocialIcons />
              </div>
            </div>
            <div className="mt-[10px]">
              <div>
                <a
                  href="/about"
                  className="block leading-none text-[16px] xl:text-lg text-white  font-roboto  capitalize mb-5"
                >
                  About us
                </a>
                <a
                  href="/jobs"
                  className="block leading-none text-[16px] xl:text-lg text-white  font-roboto capitalize mb-5"
                >
                  Jobs
                </a>
                <a
                  href="/student-discount"
                  className="block leading-none text-[16px] xl:text-lg text-white  font-roboto capitalize mb-5"
                >
                  Student Discount
                </a>
                <a
                  href="/refer-a-friend "
                  className="block leading-none text-[16px] xl:text-lg text-white  font-roboto capitalize mb-5"
                >
                  Refer a Friend
                </a>
                <a
                  href="/bounty-hotline"
                  className="block leading-none text-[16px] xl:text-lg text-white  font-roboto capitalize mb-5"
                >
                  Bounty Hotline
                </a>
              </div>
            </div>
            <div className="mt-[0] md:mt-[10px]">
              <h2 className="hidden sm:block font-vidaloka sm:text-[20px] xl:text-[24px] text-white font-bold capitalize mb-[10px]">
                Help
              </h2>

              <div>
                <a
                  href="/q&a"
                  className="block leading-none text-[16px] xl:text-lg text-white  font-roboto capitalize mb-5"
                >
                  Q&A
                </a>

                <a
                  href="/privacy-policy "
                  className="block leading-none text-[16px] xl:text-lg text-white  font-roboto capitalize mb-5"
                >
                  Privacy Policy
                </a>
                <a
                  href="/terms-and-conditions"
                  className="block leading-none text-[16px] xl:text-lg text-white  font-roboto capitalize mb-5"
                >
                  Terms & Conditions
                </a>
                <a
                  href="/contact"
                  className="block leading-none text-[16px] xl:text-lg text-white  font-roboto capitalize mb-5"
                >
                  Contact us
                </a>
              </div>
            </div>
          </div>
          <div className="flex md:hidden justify-center space-x-2 w-100%">
            <CompressedSocialIcons />
          </div>
          <div>
            <div className="flex flex-row flex-nowrap items-center justify-center cursor-pointer my-6 lg:hidden">
              <span className="block">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.6525 11.8025L7.7475 9.92L7.77 9.8975C9.075 8.4425 10.005 6.77 10.5525 5H12.75V3.5H7.5V2H6V3.5H0.75V5H9.1275C8.625 6.44 7.83 7.8125 6.75 9.0125C6.0525 8.24 5.475 7.3925 5.0175 6.5H3.5175C4.065 7.7225 4.815 8.8775 5.7525 9.92L1.935 13.685L3 14.75L6.75 11L9.0825 13.3325L9.6525 11.8025ZM13.875 8H12.375L9 17H10.5L11.34 14.75H14.9025L15.75 17H17.25L13.875 8ZM11.91 13.25L13.125 10.0025L14.34 13.25H11.91Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span className="block py-4 px-2 text-white font-roboto text-base ">
                English
              </span>
              <span className="block">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.25 8L9 11.75L12.75 8H5.25Z" fill="white" />
                </svg>
              </span>
            </div>
          </div>
          <div className="self-center my-[30px] md:my-[0]">
            {arrowUpShow ? (
              <button
                className="flex text-white mx-auto text-3xl md:text-4xl lg:text-5xl top-1/2 sm:-translate-y-1/2 left-1/2 sm:-translate-x-1/2 rotate-180"
                onClick={() => Scroll.scroller.scrollTo("home")}
              >
                <BiChevronDown />
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="bg-white py-4 md:px-4 s w-full">
        <div className="container">
          <div className="flex justify-center md:justify-between">
            <div className="flex flex-col sm:flex-row align-center sm:align-start">
              <p className="block text-xs leading-[2] md:leading-normal md:text-base font-light xl:text-lg pr-4">
                © 2022 Lionsgate. All Rights Reserved
              </p>
              <img
                src="images/footer-icons.png"
                className="max-w-[250px] sm:max-w-none"
                alt=""
              />
            </div>
            <div>
              <div className="hidden flex-row flex-nowrap items-center justify-center cursor-pointer lg:flex">
                <span className="block">
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.6525 11.8025L7.7475 9.92L7.77 9.8975C9.075 8.4425 10.005 6.77 10.5525 5H12.75V3.5H7.5V2H6V3.5H0.75V5H9.1275C8.625 6.44 7.83 7.8125 6.75 9.0125C6.0525 8.24 5.475 7.3925 5.0175 6.5H3.5175C4.065 7.7225 4.815 8.8775 5.7525 9.92L1.935 13.685L3 14.75L6.75 11L9.0825 13.3325L9.6525 11.8025ZM13.875 8H12.375L9 17H10.5L11.34 14.75H14.9025L15.75 17H17.25L13.875 8ZM11.91 13.25L13.125 10.0025L14.34 13.25H11.91Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <span className="block px-2 text-black font-roboto text-base ">
                  English
                </span>
                <span className="block">
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5.25 8L9 11.75L12.75 8H5.25Z" fill="black" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterFooter;
