import Select from "components/Select";
import React from "react";
import { useState } from "react";
import axios from "axios";

function Form() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { firstName, lastName, email } = data;

    if (firstName === "" || lastName === "" || email === "") {
      setLoading(false);
      return;
    }
    const date = new Date().toLocaleDateString();
    const res = await axios.get(
      "https://script.google.com/macros/s/AKfycbwEQN3P01Ec_4s0Syng8XVbh-LG2MF5K4k5j0QfMPv44H8Q8h5I88kg9fuSJfroAo25Bw/exec",
      {
        params: { type: "Jobs", date, name: firstName + " " + lastName, email },
      }
    );

    console.log(data);
    console.log(res);

    setData({
      firstName: "",
      lastName: "",
      email: "",
    });
    setLoading(false);
    setSuccess(true);
  };

  return (
    <form
      onSubmit={submitForm}
      className="w-full max-w-[240px] lg:max-w-[300px] xl:max-w-[345px] mx-auto text-center"
    >
      <div className="space-y-3 mb-5">
        <input
          type="text"
          className="form-input"
          placeholder="First Name"
          name="firstName"
          onChange={handler}
          value={data.firstName}
        />
        <input
          type="text"
          className="form-input"
          placeholder="Last Name"
          name="lastName"
          onChange={handler}
          value={data.lastName}
        />
        <input
          type="email"
          className="form-input"
          placeholder="Email"
          name="email"
          onChange={handler}
          value={data.email}
        />
      </div>

      <button
        disabled={loading}
        type="submit"
        className="inline-flex rounded-btn w-1/2 mx-auto justify-center"
        style={{ border: "4px solid #5EECC1" }}
      >
        {loading && (
          <svg
            className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        SUMBIT
      </button>
      {success && (
        <p className="mt-2">Your message has been sent successfully</p>
      )}
    </form>
  );
}

export default Form;
