import React from "react";
import Title from "components/Title";

import { PopupButton } from "@typeform/embed-react";
function TrustedCompanies() {
  return (
    <div>
      <div className="container">
        <div className="mb-8 md:mb-8">
          <Title triggerOnce={true} title="SUCCESFULY RECOVERED FROM" />
        </div>
        <div className="flex items-center flex-wrap justify-center -mx-4 md:-mx-6 lg:-mx-12">
          <img
            src="images/solana.png"
            alt=""
            className="w-[4em] sm:w-[5em] md:w-auto mx-4 md:mx-6 lg:mx-12 py-2 md:py-4 lg:py-5"
          />
          <img
            src="images/opensea.png"
            alt=""
            className="w-[4em] sm:w-[5em] md:w-auto mx-4 md:mx-6 lg:mx-12 py-2 md:py-4 lg:py-5"
          />
          <img
            src="images/celsius.png"
            alt=""
            className="w-[4em] sm:w-[5em] md:w-auto mx-4 md:mx-6 lg:mx-12 py-2 md:py-4 lg:py-5"
          />
          <img
            src="images/terra.png"
            alt=""
            className="w-[4em] sm:w-[5em] md:w-auto mx-4 md:mx-6 lg:mx-12 py-2 md:py-4 lg:py-5"
          />
          <img
            src="images/slope.png"
            alt=""
            className="w-[4em] sm:w-[5em] md:w-auto mx-4 md:mx-6 lg:mx-12 py-2 md:py-4 lg:py-5"
          />
          <img
            src="images/crypto.png"
            alt=""
            className="w-[4em] sm:w-[5em] md:w-auto mx-4 md:mx-6 lg:mx-12 py-2 md:py-4 lg:py-5"
          />
          <img
            src="images/trust.png"
            alt=""
            className="w-[4em] sm:w-[5em] md:w-auto mx-4 md:mx-6 lg:mx-12 py-2 md:py-4 lg:py-5"
          />
          <img
            src="images/kucoin.png"
            alt=""
            className="w-[4em] sm:w-[5em] md:w-auto mx-4 md:mx-6 lg:mx-12 py-2 md:py-4 lg:py-5"
          />
          <img
            src="images/zb.png"
            alt=""
            className="w-[4em] sm:w-[5em] md:w-auto mx-4 md:mx-6 lg:mx-12 py-2 md:py-4 lg:py-5"
          />
          <img
            src="images/near.png"
            alt=""
            className="w-[4em] sm:w-[5em] md:w-auto mx-4 md:mx-6 lg:mx-12 py-2 md:py-4 lg:py-5"
          />
          <img
            src="images/last.png"
            alt=""
            className="w-[4em] sm:w-[5em] md:w-auto mx-4 md:mx-6 lg:mx-12 py-2 md:py-4 lg:py-5"
          />
          <img
            src="images/binance.png"
            alt=""
            className="w-[4em] sm:w-[5em] md:w-auto mx-4 md:mx-6 lg:mx-12 py-2 md:py-4 lg:py-5"
          />
          <img
            src="images/FTX.png"
            alt=""
            className="w-[4em] sm:w-[5em] md:w-auto mx-4 md:mx-6 lg:mx-12 py-2 md:py-4 lg:py-5"
          />
        </div>
      </div>
    </div>
  );
}

export default TrustedCompanies;
