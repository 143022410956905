import AboutPage from "pages/AboutPage";
import Contact from "pages/Contact";
import HomePage from "pages/HomePage";
import Jobs from "pages/Jobs";
import Blog from "pages/Blog";
import QandA from "pages/Q&A";
import StudentDiscount from "pages/StudentDiscount"
import ReferAFriend from "pages/ReferAFriend"
import PrivacyPolicy from "pages/PrivacyPolicy";
import BountyHotline from "pages/BountyHotline";
import TermsAndConditions from "pages/TermsAndConditions";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="jobs" element={<Jobs />} />
        <Route path="q&a" element={<QandA />} />
        <Route path="blog" element={<Blog />} />
        <Route path="contact" element={<Contact />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="bounty-hotline" element={<BountyHotline />} />
        <Route path="student-discount" element={<StudentDiscount />} />
        <Route path="refer-a-friend" element={<ReferAFriend />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
