import useMediaQuery from "hooks/useMediaQuery";
import FooterFooter from "layouts/FooterFooter";
import Navbar from "layouts/Navbar";
import Title from '../components/Title'
import Accordion from "components/Accordion";

function FandQ() {
  const isAbove1024px = useMediaQuery("(min-width:1024px)");

  return (
    <div className="relative bg-blue">
      <Navbar className="bg-blue" />
      <div className="mb-16 sm:mb-20 xl:mb-150px ">
        <div className="container pt-8 sm:pt-12">
          <h1 className="text-center text-white text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-8 lg:mb-16">
            Q&A
          </h1>

          <div className="text-left">
                <div className="space-y-4 sm:space-y-6 lg:space-y-8">
                  <Accordion title="Is crypto lost forever?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        Yes and no. Depends on the case. If you got hacked or invested in a company that filed for bankruptcy and now all your money is gone - there is a solution. On the other hand, if you lost your seed phrase to your wallet, it's very likely your crypto is lost. In fact, according to chainalysis, 25% of all BTC is lost forever. 
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Is it possible to recover lost cryptocurrency?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        Bitcoin (BTC) and other cryptocurrencies can be recovered depending on the circumstances, if you take the right steps.
                        Here are some different circumstances when you might be able to recover lost cryptocurrency:A crypto wallet got hacked, funds have plunged with Celsius, FTX, etc., or a sudden block in accessing an account. Contact the Lionsgate support center to find out whether it's possible to recover your funds.
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="How can I recover my lost crypto money?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                       Lionsgate can help you recover your lost crypto money. We’ve built a unique company-wide integration between blockchain experts and legal personnel that is the key to Lionsgate's success. The methods and tactics we use to recover your lost funds are advanced and mostly used by big organizations, financial institutes and governments. 
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="How do I find my lost crypto?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        Your seed phrase unlocks both the wallet and the crypto inside when you use a wallet. We can trace your money on the blockchain if you were hacked, locate the holder of your funds in order to file a lawsuit against them and get you the best settlement. 
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="How much crypto has been lost or stolen?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        In 2021 hackers have stolen over 14 billion USD. In 2022 hackers have already stolen nearly $2 billion worth of cryptocurrency… As of July, $1.9 billion in crypto has been stolen by cybercriminal hacks, according to Chainalysis' “Mid-year Crypto Crime Update.” 
A staggering total of 28 billion USD have been lost/stolen in cryptocurrency.
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="How do I begin the recovery process for my lost crypto money?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        First complete Lionsgate’s Recovery Wizard™. Upon determining whether your case can be resolved, we'll schedule a call with our support center, so that we can begin the process immediately. The sooner we start, the better.
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Can Lionsgate track a crypto scammer?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                       Lionsgate traces scammers on Web3 (Address) and Web2 (Accounts, URL’s, etc.) as well as using OSIN  (Open-source intelligence) which can help to reach a quick settlement on the behalf of Lionsgate clients. 
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Can I recover my lost BTC?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        If you forget your seed phrase, you will most likely not be able to recover your funds. In case you still have access to your seed phrase, get in touch with a Lionsgate support center ASAP.
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Will FTX victims get their money back?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        Customers of bankrupt cryptocurrency exchange FTX Trading can immediately start a recovery process with Lionsgate to claim their lost money. The sooner Lionsgate acts, the better chance of recovering investors' lost crypto. 
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Will Celsius victims get their money back?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        Lionsgate can certainly assist Celsius Network victims in recouping their investments. Due to Celsius's bankruptcy declaration, immediate action is necessary. Lionsgate will analyze the case and contact its legal team to negotiate a fair settlement.
                      </p>
                    </div>
                  </Accordion>
                  <Accordion title="Can I get my funds back from FTX?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        Certainly, but you have to go through a recovery process for Lionsgate's investment you lost.
Even though FTX has already filed for bankruptcy, Lionsgate’s team will contact their legal team and make sure you get the best settlement. 
                      </p>
                    </div>
                  </Accordion>

                  <Accordion title="Can hackers steal cryptocurrency?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        A staggering total of 28 billion USD have been lost/stolen in cryptocurrency. Nearly every month, there are stories of crypto exchanges being hacked and thousands or millions of dollars of cryptocurrency being stolen.
                      </p>
                    </div>
                  </Accordion>

                  <Accordion title="How do hackers steal cryptocurrency?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        There are two main ways criminals obtain cryptocurrency: stealing it directly, or using a scheme to trick people into handing it over. In 2021, crypto criminals directly stole a record US$3.2 billion (A$4.48 billion) worth of cryptocurrency, according to Chainalysis.
                      </p>
                    </div>
                  </Accordion>

                  <Accordion title="Premium Express Service: How do I join?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        Premium Express will enable you to get your money back faster and with a double team of specialists at your disposal, you will be able to resolve the issue quickly and receive the best compensation possible as soon as possible.
                      </p>
                    </div>
                  </Accordion>
                  
                  <Accordion title="When will I get my money back?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        Each case is unique, therefore there is no one-size-fits-all solution. Some cases may take around 10 business days, while others may take longer. Throughout the course of your case, your personal representative will provide you with regular updates until your case has been resolved completely and to your satisfaction.
                      </p>
                    </div>
                  </Accordion>

                  <Accordion title="Where is Lionsgate located? ">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        We have teams located in Tel Aviv, London, Kiev, and Sao Paulo. Besides being the center of cybersecurity, Tel Aviv is also home to some of the best cybersecurity specialists who work for Microsoft, Google, Checkpoint, and many other companies. 
                      </p>
                    </div>
                  </Accordion>

                  <Accordion title="Can you tell me more about Lionsgate?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        The company was founded by veterans of the Israel Defense Forces Intelligence Corps. The company has three main departments: Cyber, Blockchain Analysis, and Legal. Lionsgate's private customers and cooperatives benefit from the same tools as governments and global financial organizations.
                      </p>
                    </div>
                  </Accordion>

                  <Accordion title="How do I begin the recovery process with Lionsgate?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        First complete Lionsgate’s Recovery Wizard™. It takes 60 seconds to complete. If we believe your case is solvable, we’ll schedule a call with our support center, so that we can then immediately begin the process. Remember: The sooner we start, the better.
                      </p>
                    </div>
                  </Accordion>

                  <Accordion title="What's the backend process at Lionsgate?">
                    <div className="">
                      <p className="text-xs md:text-base text-white opacity-80 max-w-[30rem] font-light">
                        There are three main pillars to the company. Legal, cybersecurity, and blockchain analysis.
The blockchain team traces and isolates your transaction, the cyber team gathers clues about the current holder of your funds using OSINT, and a legal team demands the best settlement for you.
                      </p>
                    </div>
                  </Accordion>
                </div>
          </div>
        </div>
      </div>
      <FooterFooter />
    </div>
  );
}

export default FandQ;
